export const transferPuffPalz = `
import NonFungibleToken from 0x1d7e57aa55817448
import PuffPalz from 0xa3eb9784ae7dc9c8

transaction (receiverAddress: Address, nftId: UInt64){
    let adminCap: &PuffPalz.Collection
    let recipient: &{NonFungibleToken.CollectionPublic}

    prepare(admin: AuthAccount) {
        self.adminCap = admin.borrow<&PuffPalz.Collection>(from: PuffPalz.CollectionStoragePath)!
        self.recipient = getAccount(receiverAddress)
            .getCapability<&{NonFungibleToken.CollectionPublic, PuffPalz.CollectionPublic}>(PuffPalz.CollectionPublicPath)
            .borrow() ?? panic("Cannot borrow PuffPalz NFT collection receiver from account")
    }

    execute {




            let nft <- self.adminCap.withdraw(withdrawID: nftId)
            self.recipient.deposit(token: <- nft)


    }
}
`