import React, { useRef, useEffect, useState } from 'react';
import '../../App.css';
import logo from '../../assets/images/mint_new.jpg';
import { purchasePuffPal } from '../../cadence/transactions/purchasePuffPalListing';
import { getListingIDs } from '../../cadence/scripts/getListingIDs';
import * as fcl from '@blocto/fcl';
import metadata from './metadata.json';
import { getPuffPalzIDs } from '../../cadence/scripts/getPuffPalzIDs';
import {checkFlowBalance} from '../../cadence/scripts/checkFlowBalance';




// Helper function to dynamically import images
function importAll(r) {
  let images = {};
  r.keys().forEach(item => { 
    images[item.replace('./', '')] = r(item); 
  });
  return images;
}

// Shuffle function
function shuffle(array) {
  let currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}


// Import images from directories and shuffle them
const smokersImages = shuffle(Object.values(importAll(require.context('../../assets/images/smokers/', false, /\.(jpeg)$/))));
const pushersImages = shuffle(Object.values(importAll(require.context('../../assets/images/pushers/', false, /\.(jpeg)$/))));
const growersImages = shuffle(Object.values(importAll(require.context('../../assets/images/growers/', false, /\.(jpeg)$/))));
const specialImages = shuffle(Object.values(importAll(require.context('../../assets/images/special/', false, /\.(jpeg)$/))));

function Shop() {
  const scrollContainerRef1 = useRef(null);
  const scrollContainerRef2 = useRef(null);
  const scrollContainerRef3 = useRef(null);
  const [startScroll, setStartScroll] = useState(false);
  const [listings, setListings] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [user, setUser] = useState({ loggedIn: null });  
  //const [amountFlow, setAmountFlow] = useState("");
  const [userAddress, setUserAddress] = useState(null);
  const [mintSuccess, setMintSuccess] = useState(false);
const [mintedItemDetails, setMintedItemDetails] = useState(null);
const [displayedText, setDisplayedText] = useState('');
const isMounted = useRef(true);
const [formattedImageName, setFormattedImageName] = useState('');
const [uniqueKey, setUniqueKey] = useState(Date.now());
const [mintingText, setMintingText] = useState('');
const [puffPalzIDs, setPuffPalzIDs] = useState([]);
const [remainingPuffPalz, setRemainingPuffPalz] = useState(null);
const [flowBalance, setFlowBalance] = useState(null);


async function fetchPuffPalzIDs() {
  try {
      const details = await fcl.query({
          cadence: getPuffPalzIDs,
          args: (arg, t) => [arg("0xa3eb9784ae7dc9c8", t.Address)],
      });
      setPuffPalzIDs(details);
  } catch (error) {
      console.error("Error fetching Puff Palz details:", error);
  }
}

useEffect (() => {
  fetchPuffPalzIDs();
}, []);

useEffect (() => {
  setRemainingPuffPalz(puffPalzIDs.length);
}, [puffPalzIDs]);

useEffect(() => {
  let intervalId;
  if (isProcessing) {
      const mintingSteps = ['M', 'MI', 'MIN', 'MINT', 'MINTI', 'MINTIN', 'MINTING'];
      let step = 0;
      intervalId = setInterval(() => {
          setMintingText(mintingSteps[step]);
          step = (step + 1) % mintingSteps.length; // Cycle through steps and reset to 0 after "MINTING"
      }, 500);
  } else {
      setMintingText(''); // Reset when not processing
  }

  return () => clearInterval(intervalId); // Cleanup on unmount or dependency change
}, [isProcessing]);// Dependency array includes all isProcessingX variables



useEffect(() => {
  // When component mounts
  return () => {
    // When component unmounts, set isMounted to false
    isMounted.current = false;
  };
}, []);

  useEffect(() => {
    fcl.currentUser.subscribe((currentUser) => {
      console.log("Before setting user:", user);
      setUser(currentUser);
      console.log("After setting user:", currentUser);
      setUserAddress(currentUser.addr);
    });
  }, []);

  const fetchListings = async () => {
    const fetchedListings = await fcl.query({
      cadence: getListingIDs,
      //args: (arg, t) => [arg("0xa3eb9784ae7dc9c8", t.Address)], // Update based on how your Cadence script expects arguments
    });

    // Fetch details for each listing
    const allDetails = await Promise.all(fetchedListings.map(id => fetchListingDetails(id)));

    // Filter out purchased listings and specific nftIDs
    const excludedNftIDs = [4, 263]; // Define IDs to exclude
    const availableListings = allDetails.filter(details => 
        details && !details.purchased && !excludedNftIDs.includes(details.nftID));

   // console.log(`Available listings count: ${availableListings.length}`, availableListings);
    
    if (availableListings.length === 0) {
      setDisplayedText("Sold Out");
    } else {
      setListings(availableListings.map(detail => detail.listingID)); // Assuming you store the listingID in the details
    }
};

  
  useEffect(() => {
    fetchListings();
  }, []); 

  useEffect(() => {
 //   console.log("Listing IDs: ", listings);
//    console.log("Listing Length: ", listings.length);
  }, [listings]); 



  // Whenever mintedItemDetails changes, update the unique key to force a re-render
  useEffect(() => {
    if (mintedItemDetails) {
    //  console.log("Minted Item Details updated:", mintedItemDetails);
      setUniqueKey(Date.now()); // Update key to force re-render
    }
  }, [mintedItemDetails]);


  function formatName(name) {
    return name.split(' ')
               .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
               .join('');
}


  const handleMint = async () => {
    if (!listings.length) {
   //   console.log("No listings available.");
      setDisplayedText("Sold Out");
      return;
    }
  
    setIsProcessing(true);
    setMintSuccess(false);
    setMintedItemDetails(null);  // Clear previous minted item details
  
    const randomListing = listings[Math.floor(Math.random() * listings.length)];
    const listingDetails = await fetchListingDetails(randomListing);

      // Find metadata that matches the NFT ID
    // Find metadata that matches the NFT ID
    const matchedMetadata = metadata.find(meta => meta.id === listingDetails.nftIDs[0]);
 //   console.log("Listing Details: ", matchedMetadata);



  
    try {
      const TXid = await fcl.mutate({
        cadence: purchasePuffPal,
        args: (arg, t) => [
          arg(randomListing, t.UInt64),
          arg("0xa3eb9784ae7dc9c8", t.Address)
        ],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });
  
      console.log("Processing Transaction: ", TXid);
      await fcl.tx(TXid).onceSealed();
  
      // Fetch metadata matching the NFT ID
    // Fetch metadata matching the NFT ID
    const matchedMetadata = metadata.find(meta => meta.id === listingDetails.nftIDs[0]);
    if (matchedMetadata) {
      // Assuming that you have a method to get the IPFS url using cid
      const ipfsUrl = getIpfsUrl(matchedMetadata.image);
      setMintedItemDetails({
        name: matchedMetadata.name,
        cid: matchedMetadata.image, // or ipfsUrl if you want to store the full URL
        role: matchedMetadata.traits.Role.toLowerCase()
      });
    } else {
      setMintedItemDetails({
        name: "Unknown",
        cid: "Unknown",
        role: "default"
      });
    }

 //   console.log(`Minting succeeded: ${matchedMetadata.name}`);
    setIsProcessing(false);
    setMintSuccess(true);
    fetchFlowBalance();

    fetchListings();
    setStartScroll(false);

    setTimeout(() => {
      setMintSuccess(false);
    }, 7000);

  } catch (error) {
    console.error("Minting transaction failed", error);
    setIsProcessing(false);
    setStartScroll(false);

  }
};

useEffect(() => {
  if (mintSuccess || isProcessing) {
      setStartScroll(false);
  } else {
      setStartScroll(true); // Reactivate scrolling when not processing or successful
  }
}, [mintSuccess, isProcessing]);

const getIpfsUrl = (cid) => `https://ipfs.io/ipfs/${cid}`;


  
  
  
  const fetchListingDetails = async (listingID) => {
    try {
      const details = await fcl.query({
        cadence: `
          import PuffPalzMainStorefront from 0xa3eb9784ae7dc9c8
  
          pub fun main(storefrontAddress: Address, listingResourceID: UInt64): PuffPalzMainStorefront.ListingDetails? {
            let storefrontRef = getAccount(storefrontAddress)
                .getCapability<&PuffPalzMainStorefront.Storefront{PuffPalzMainStorefront.StorefrontPublic}>(
                    PuffPalzMainStorefront.StorefrontMainPublicPath
                )
                .borrow()
                ?? panic("Could not borrow the storefront reference")
            
            let listing = storefrontRef.borrowListing(listingResourceID: listingResourceID)
            return listing?.getDetails()
          }
        `,
        args: (arg, t) => [arg("0xa3eb9784ae7dc9c8", t.Address), arg(listingID, t.UInt64)]
      });
  
      if (!details) {
        console.log(`No details found for listing ID: ${listingID}`);
        return null;
      }
  
      console.log("Name: ", details); // Assuming these fields are returned
      return {
        ...details,
        listingID  // Keep listingID in the result for reference
      };
  
    } catch (error) {
      console.error('Error fetching listing details:', error);
      return null;
    }
  };

  async function fetchFlowBalance() {
    try {
        const balance = await fcl.query({
            cadence: checkFlowBalance,
            args: (arg, t) => [arg(userAddress, t.Address)],
        });
        const formattedBalance = Number(balance).toFixed(2);

        setFlowBalance(formattedBalance); // Update the balance state with the formatted value
        console.log("Flow Balance:", formattedBalance); //
    } catch (error) {
        console.error("Error fetching TIT balance:", error);
    }
}

useEffect(() => {
    
    fetchFlowBalance();
}, [userAddress]);

  
  
  

  // Combine all images, with the logo at the start, and shuffle each time for randomness
  const imagesWithLogo1 = [logo, ...shuffle([...smokersImages, ...pushersImages, ...growersImages, ...specialImages])];
  const imagesWithLogo2 = [logo, ...shuffle([...smokersImages, ...pushersImages, ...growersImages, ...specialImages])];
  const imagesWithLogo3 = [logo, ...shuffle([...smokersImages, ...pushersImages, ...growersImages, ...specialImages])];



  const imagesWithLogo = [`${logo}?v=${uniqueKey}`, ...Object.values(smokersImages), ...Object.values(pushersImages), ...Object.values(growersImages), ...Object.values(specialImages)];
  
  const startScrolling = () => {
    const scrollRefs = [scrollContainerRef1, scrollContainerRef2, scrollContainerRef3];
    const directions = [1, -1, 1]; // directions for scrolling
    const speeds = [9, 7, 11]; // speeds for scrolling
  
    const intervals = scrollRefs.map((ref, idx) => {
      return setInterval(() => {
        if (startScroll && ref.current) { // Check if ref.current exists before accessing it
          let newScrollTop = ref.current.scrollTop + (directions[idx] * speeds[idx]);
          if (newScrollTop >= ref.current.scrollHeight - ref.current.clientHeight) {
            newScrollTop = 0;
          } else if (newScrollTop <= 0) {
            newScrollTop = ref.current.scrollHeight - ref.current.clientHeight;
          }
          ref.current.scrollTop = newScrollTop;
        }
      }, 50);
    });
  
    return () => intervals.forEach(interval => clearInterval(interval));
  };
  
  useEffect(() => {
    if (scrollContainerRef1.current && scrollContainerRef2.current && scrollContainerRef3.current) {
      scrollContainerRef1.current.scrollTop = scrollContainerRef1.current.scrollHeight / 2; // Middle image
      scrollContainerRef2.current.scrollTop = scrollContainerRef2.current.scrollHeight - scrollContainerRef2.current.clientHeight; // Reverse order
      // Right container starts at the top, no need to set scrollTop
    }
  }, []);
  useEffect(() => {
    if (!startScroll) {
      // If startScroll is false, do nothing and avoid setting up any intervals
      return;
    }
  
    const clearIntervals = startScrolling(); // startScrolling sets up the intervals
  
    return () => {
      // This cleanup function is called when the component unmounts or when the dependency changes
      clearIntervals(); // It calls the function to clear all intervals
    };
  }, [startScroll]); // 

  useEffect(() => {
    let interval1, interval2, interval3;
  

  
    if (startScroll) {

      // Start scrolling with random speeds and directions
      interval1 = startScrolling(scrollContainerRef1);
      interval2 = startScrolling(scrollContainerRef2);
      interval3 = startScrolling(scrollContainerRef3);
    }
  
    // Dynamically adjust the height of the image containers
    const adjustHeight = () => {
      [scrollContainerRef1, scrollContainerRef2, scrollContainerRef3].forEach(ref => {
        if (ref.current && ref.current.firstChild) {
          const firstImageHeight = ref.current.firstChild.offsetHeight;
          ref.current.style.height = `${firstImageHeight}px`; // Set container height to match first image
        }
      });
    };
  
    // Adjust the height on mount and on window resize
    window.addEventListener('resize', adjustHeight);
    adjustHeight();
  
    // Clear intervals on component unmount or when stop scrolling
    return () => {
      if (interval1) clearInterval(interval1);
      if (interval2) clearInterval(interval2);
      if (interval3) clearInterval(interval3);
      //window.removeEventListener('resize', adjustHeight); // Cleanup the event listener
    };
  
  }, [startScroll]); 
  

  useEffect(() => {
    if (isProcessing) {
      setStartScroll(true); // Start scrolling when processing
    }
  }, [isProcessing]);



  

  return (
    <div className="App" key={uniqueKey}> 
      <div className='top-container'>
        <h1>mint Puff Palz</h1>
        <h4>{remainingPuffPalz}/420</h4> 
      </div>

      <div className='middle-container'>
        <h3>Your Flow Balance</h3>
        <p>{user.loggedIn ? (flowBalance ? `${flowBalance} FLOW` : 'Loading balance...') : 'Log in to view balance'}</p>
      </div>

        <div className="scrolling-images-wrapper">
          

        {
        !isProcessing && !mintSuccess ? 
        // Display the mint image for all containers when not processing and not mint success
        [scrollContainerRef1, scrollContainerRef2, scrollContainerRef3].map((ref, idx) => (
          <div className="image-scroll-container" key={idx}>
            <img 
              src={logo} 
              alt="Mint" 
              className="scrolling-image"
            />
          </div>
        )) :
        // Otherwise, show the scrolling images or the minted item
        [scrollContainerRef1, scrollContainerRef2, scrollContainerRef3].map((ref, idx) => (
          <div className="image-scroll-container" ref={ref} key={idx}>
            {
              mintSuccess && mintedItemDetails ? (
                <img 
                key={`image-${idx}`} 

                src={`./${mintedItemDetails.name}.jpeg`}

                  alt={`Minted Item: ${mintedItemDetails.name}`} 
                  className="scrolling-image"
                />
              ) : (
                imagesWithLogo.map((src, index) => (
                  <img 
                    key={`${idx}-${index}`} 
                    src={src} 
                    alt={`Image ${index}`} 
                    className="scrolling-image" 
                  />
                ))
              )
            }
          </div>
        ))
      }

              
          <div className="button-row">
              <h5 className="price-info">14.20 $FLOW</h5>
              {isProcessing ? (
                  <p className="minting-text">{mintingText}</p>
              ) : mintSuccess ? (
                  <p className="minting-text">Congratulations! You received {mintedItemDetails?.name}</p>
              ) : (
                  flowBalance >= 14.20 ? (
                      <button onClick={handleMint} className='mint-button'>MINT</button>
                  ) : (
                    <div className='middle-container-new'>
                      <p>Please add funds to mint.</p>
                    </div>
                  )
              )}
          </div>

        </div>

    </div>
  );
}



export default Shop;
