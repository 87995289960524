export const getListingIDs = `
import PuffPalzMainStorefront from 0xa3eb9784ae7dc9c8

pub fun main(): [UInt64] {
    let storefrontAddress = Address(0xa3eb9784ae7dc9c8)   // Replace with actual storefront address if different
    let storefrontPublicPath = PuffPalzMainStorefront.StorefrontMainPublicPath

    // Borrow the public interface of the Storefront resource
    let storefrontPublic = getAccount(storefrontAddress)
        .getCapability(storefrontPublicPath)
        .borrow<&PuffPalzMainStorefront.Storefront{PuffPalzMainStorefront.StorefrontPublic}>()
        ?? panic("Could not borrow storefront public interface")

    // Retrieve and return the listing IDs
    return storefrontPublic.getListingIDs()
}
`