export const getPartyFavorz = `
import TouchstoneGalacticGourmet from 0xc4b1f4387748f389
import NonFungibleToken from 0x1d7e57aa55817448
import TouchstoneMidnightMunchies from 0xc4b1f4387748f389
import TouchstonePartyFlavorz from 0xc4b1f4387748f389
import TouchstoneSnowyGlobez from 0xc4b1f4387748f389
import TouchstoneSnowGlobez from 0xc4b1f4387748f389
import TouchstoneSnowGlobz from 0xc4b1f4387748f389

pub fun main(accountAddress: Address): [UInt64] {
    var allNFTIDs: [UInt64] = []

    let collections: [Capability<&{NonFungibleToken.CollectionPublic}>] = [
        getAccount(accountAddress).getCapability<&TouchstoneGalacticGourmet.Collection{NonFungibleToken.CollectionPublic}>(TouchstoneGalacticGourmet.CollectionPublicPath),
        getAccount(accountAddress).getCapability<&TouchstoneMidnightMunchies.Collection{NonFungibleToken.CollectionPublic}>(TouchstoneMidnightMunchies.CollectionPublicPath),
        getAccount(accountAddress).getCapability<&TouchstonePartyFlavorz.Collection{NonFungibleToken.CollectionPublic}>(TouchstonePartyFlavorz.CollectionPublicPath),
        getAccount(accountAddress).getCapability<&TouchstoneSnowyGlobez.Collection{NonFungibleToken.CollectionPublic}>(TouchstoneSnowyGlobez.CollectionPublicPath),
        getAccount(accountAddress).getCapability<&TouchstoneSnowGlobez.Collection{NonFungibleToken.CollectionPublic}>(TouchstoneSnowGlobez.CollectionPublicPath),
        getAccount(accountAddress).getCapability<&TouchstoneSnowGlobz.Collection{NonFungibleToken.CollectionPublic}>(TouchstoneSnowGlobz.CollectionPublicPath)
    ]

    for collectionCap in collections {
        if let collectionRef = collectionCap.borrow() {
            let nftIDs = collectionRef.getIDs()
            for id in nftIDs {
                allNFTIDs.append(id) // Append each ID individually
            }
        }
    }

    return allNFTIDs
}

`