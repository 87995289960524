import React, { useState, useEffect } from 'react';
import '../../App.css';
import logo from '../../assets/images/logoMain.webp';
import { getPartyFavorz } from '../../cadence/scripts/getPartyFavorzIDs';
import * as fcl from '@blocto/fcl';
import { transferFlowLogPurchase } from '../../cadence/transactions/transferFlowLogPurchase';
import { getPreSaleOrders } from '../../cadence/scripts/getPreSaleOrders';
import { checkFlowBalance } from '../../cadence/scripts/checkFlowBalance';
import { getPuffPalzIDs } from '../../cadence/scripts/getPuffPalzIDs';
import { initPuffPalz } from '../../cadence/transactions/initPuffPalz';
import { useNavigate } from 'react-router-dom';
import { getListingIDs } from '../../cadence/scripts/getListingIDs';
import { purchasePuffPal } from '../../cadence/transactions/purchasePuffPalListing';


function Waiting() {
    const [user, setUser] = useState({ loggedIn: false, addr: null });
    const [userAddress, setUserAddress] = useState(null);
    const [ownsPF, setOwnsPF] = useState(true);
    const [accessMessage, setAccessMessage] = useState('');
    const [transactionCompleted, setTransactionCompleted] = useState(false); // New state to track transaction completion
    const [hasPreSale, setHasPreSale] = useState(false);
    const [flowBalance, setFlowBalance] = useState(0);
    const [partyFavorzOwned, setPartyFavorzOwnned] = useState(0);
    const [puffPalzOwned, setPuffPalzOwned] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);
    const [dots, setDots] =useState("")
    const [preSaleCount, setPreSaleCount] = useState(0);
    const [puffPalzIDs, setPuffPalzIDs] = useState([]);
    const [isInitializing, setIsInitializing] = useState(false);
    const [listings, setListings] = useState([]);

    const [mintSuccess, setMintSuccess] = useState(false);
  const [mintedItemDetails, setMintedItemDetails] = useState(null);
  const [displayedText, setDisplayedText] = useState('');

  const [formattedImageName, setFormattedImageName] = useState('');
  const [uniqueKey, setUniqueKey] = useState(Date.now());
  const [mintingText, setMintingText] = useState('');

    const handleMint = async () => {
      if (!listings.length) {
        setDisplayedText("All Pre-Sale Puff Palz Collected");
        return;
      }
    
      if (!hasPreSale) {
        alert("You need a pre-sale to mint these Puff Palz!");
        return;
      }
    
      setIsProcessing(true);

    
      const randomListing = listings[Math.floor(Math.random() * listings.length)];
      const listingDetails = await fetchListingDetails(randomListing);
    
      try {
        const TXid = await fcl.mutate({
          cadence: purchasePuffPal,
          args: (arg, t) => [
            arg(randomListing, t.UInt64),
            arg("0xa3eb9784ae7dc9c8", t.Address)
          ],
          proposer: fcl.currentUser,
          payer: fcl.currentUser,
          authorizations: [fcl.currentUser],
          limit: 999,
        });
    
        await fcl.tx(TXid).onceSealed();
        if (listingDetails.price === 0) {
          setMintedItemDetails({
            name: listingDetails.name,
            cid: listingDetails.image,
            role: listingDetails.traits.Role.toLowerCase()
          });
        }
    
        setIsProcessing(false);

        fetchListings();
 
    

    
      } catch (error) {
        console.error("Minting transaction failed", error);
        setIsProcessing(false);
  
      }
    };
    


    const navigate = useNavigate();

    const fetchListings = async () => {
      const fetchedListings = await fcl.query({
        cadence: getListingIDs,
        //args: (arg, t) => [arg("0xa3eb9784ae7dc9c8", t.Address)], // Update based on how your Cadence script expects arguments
      });
    
      const allDetails = await Promise.all(fetchedListings.map(id => fetchListingDetails(id)));
    
      // Filter out purchased listings, specific nftIDs, and non-zero priced listings
      const availableListings = allDetails.filter(details => 
        details && !details.purchased && details.price === 0.0);
    
      if (availableListings.length === 0) {
        setDisplayedText("Sold Out");
      } else {
        setListings(availableListings.map(detail => detail.listingID)); // Assuming you store the listingID in the details
      }
    };
    
  

    const fetchListingDetails = async (listingID) => {
      try {
        const details = await fcl.query({
          cadence: `
            import PuffPalzMainStorefront from 0xa3eb9784ae7dc9c8
    
            pub fun main(storefrontAddress: Address, listingResourceID: UInt64): PuffPalzMainStorefront.ListingDetails? {
              let storefrontRef = getAccount(storefrontAddress)
                  .getCapability<&PuffPalzMainStorefront.Storefront{PuffPalzMainStorefront.StorefrontPublic}>(
                      PuffPalzMainStorefront.StorefrontMainPublicPath
                  )
                  .borrow()
                  ?? panic("Could not borrow the storefront reference")
              
              let listing = storefrontRef.borrowListing(listingResourceID: listingResourceID)
              return listing?.getDetails()
            }
          `,
          args: (arg, t) => [arg("0xa3eb9784ae7dc9c8", t.Address), arg(listingID, t.UInt64)]
        });
    
        if (!details) {
          console.log(`No details found for listing ID: ${listingID}`);
          return null;
        }
    
        console.log("Name: ", details); // Assuming these fields are returned
        return {
          ...details,
          listingID  // Keep listingID in the result for reference
        };
    
      } catch (error) {
        console.error('Error fetching listing details:', error);
        return null;
      }
    };
    

    const handleInitPuffPalz = async () => {
      setIsInitializing(true);
      try {
          const txid = await fcl.mutate({
              cadence: initPuffPalz,
              args: (arg, t) => [], // Assuming no arguments are required
              proposer: fcl.currentUser,
              payer: fcl.currentUser,
              authorizations: [fcl.currentUser],
              limit: 999,
          });
          await fcl.tx(txid).onceSealed();
          setIsInitializing(false);
          alert('Initialization successful!'); // Notify user of success
      } catch (error) {
          console.error('Initialization failed:', error);
          setIsInitializing(false);
      }
  };

    useEffect(() => {
      // Determine if any isProcessing state is true
      const anyProcessing = [isProcessing].some(state => state);
    
      let intervalId;
      if (anyProcessing) {
        intervalId = setInterval(() => {
          // Update dots state to cycle through '', '.', '..', '...'
          setDots(dots => {
            switch (dots) {
              case '.':
                return 'PUFF';
              case 'PUFF':
                return 'PUFF PUFF';
              case 'PUFF PUFF':
                return 'PUFF PUFF PASS';
              default:
                return '.';
            }
          });
        }, 500); // Adjust time as needed
      }
    
      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    }, [isProcessing]); // Dependency array includes all isProcessingX variables
    

    const transferFlowPreSale = async (timestamp, txID) => {
        // Assume the timestamp and txID are passed correctly to this function
        try {
          setIsProcessing(true);
        const txid = await fcl.mutate({
            cadence: transferFlowLogPurchase,
            args: (arg, t) => [
                arg("14.20", t.UFix64),
                arg(timestamp, t.String),
                arg(txID, t.String),
                arg(userAddress, t.Address)
            ],
            proposer: fcl.currentUser,
            payer: fcl.currentUser,
            authorizations: [fcl.currentUser],
            limit: 999,
        });
        await fcl.tx(txid).onceSealed();
        setIsProcessing(false);
        setHasPreSale(true);
    } catch (error) {
      setIsProcessing(false);
    }
  };


    useEffect(() => {
      const unsubscribe = fcl.currentUser().subscribe(user => {
        setUser({
          loggedIn: user.loggedIn,
          addr: user.addr,
        });
      });
  
      return () => unsubscribe();
    }, []);

    // Effect to update userAddress based on user.addr
    useEffect(() => {
        setUserAddress(user.addr);
        if (user.addr) {
          getPartyFavorzIDs(user.addr);
          console.log(ownsPF) // Pass the user address to the function
        }
    }, [user.addr]);

    useEffect(() => {

        console.log(ownsPF) // Pass the user address to the function
        
    }, [ownsPF]);

  // Set the target date and time for Hawaii, which is GMT-10
  const targetTime = new Date('April 20, 2024 10:20:00 GMT-1000').getTime();


  // State to store the countdown timer
  const [countdownTimer, setCountdownTimer] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  });

  useEffect(() => {
    // Update the countdown every second
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetTime - now;

      // Calculate time left
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Prefix with zero if less than 10
      days = days < 10 ? '0' + days : days;
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      // Update state
      setCountdownTimer({ days, hours, minutes, seconds });

      // If the countdown is over, clear the interval
      if (distance < 0) {
        clearInterval(interval);
        setCountdownTimer({ days: '00', hours: '00', minutes: '00', seconds: '00' });
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  async function getPartyFavorzIDs(userAddress) {
    try {
        console.log("User Address: ", userAddress);
        const output = await fcl.query({
            cadence: getPartyFavorz,
            args: (arg, t) => [arg(userAddress, t.Address)], // Use the passed address
        });

        console.log(output.length);
        setPartyFavorzOwnned(output.length);
        //setPartyFavorzOwnned(1);
        // Check if the output array has any elements
        setOwnsPF(output.length > 0);
        //setOwnsPF(true);
    } catch (error) {
        console.error("Error fetching PartyFavorz IDs:", error);
        setOwnsPF(false); // Ensure ownsPF is set to false in case of an error
    }
    }

    async function checkPreSaleOrders() {
        try {
            const outputOrders = await fcl.query({
                cadence: getPreSaleOrders,
                args: (arg, t) => [], // No arguments needed for this query
            });
    
            // Convert the object of orders to an array of orders
            const ordersArray = Object.values(outputOrders);
            console.log("Converted Orders Array: ", ordersArray);

            // Update the pre-sale count state
            setPreSaleCount(ordersArray.length);
    
            // Check if any order's userAddress matches the current user's address
            const userHasPreSale = ordersArray.some(order => order.userAddress === user.addr);
            console.log("User Pre-Sale?", userHasPreSale);
    
            // Update state based on whether the user has pre-sale orders
            setHasPreSale(userHasPreSale);
        } catch (error) {
            console.error("Error fetching PreSale Orders:", error);
            setHasPreSale(false); // Assume the user does not have a pre-sale in case of an error
        }
    }

    useEffect(() => {
      if(hasPreSale) {
        setPuffPalzOwned(1);
      }
    }, [hasPreSale]);
    

    useEffect(() => {
        if (user.addr) {
            checkPreSaleOrders();
        }
    }, [user.addr]); // Runs whenever userAddress changes
    
    async function checkPreSale() {
      try {
          const outputOrders = await fcl.query({
              cadence: getPreSaleOrders,
              args: (arg, t) => [], // No arguments needed for this query
          });
  
          // Convert the object of orders to an array of orders
          const ordersArray = Object.values(outputOrders);


          // Update the pre-sale count state
          setPreSaleCount(ordersArray.length);

      } catch (error) {
          console.error("Error fetching PreSale Orders:", error);
      }
  }


  useEffect(() => {
        checkPreSale();
}, []);




    async function fetchFlowBalance() {
      try {
          const balance = await fcl.query({
              cadence: checkFlowBalance,
              args: (arg, t) => [arg(userAddress, t.Address)],
          });
          const formattedBalance = Number(balance).toFixed(2);

          setFlowBalance(formattedBalance); // Update the balance state with the formatted value
          console.log("Flow Balance:", formattedBalance); //
      } catch (error) {
          console.error("Error fetching TIT balance:", error);
      }
  }

  useEffect(() => {

    fetchFlowBalance();

}, [userAddress]);
    

    // Modify this function
    const handleButtonClick = async () => {
      //  if (ownsPF) {
            const timestamp = new Date().toISOString();
            const txID = generateRandomTxID();
            await transferFlowPreSale(timestamp, txID);
            setTransactionCompleted(true); // Update state to indicate completion
      //  } else {
      //      setAccessMessage('Access Denied');
      //  } 
    };

    // New function to generate a 16-digit alphanumeric string with the fifth character as 'C'
    function generateRandomTxID() {
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = '';
        for (let i = 0; i < 16; i++) {
            if (i === 4) {
                result += 'C';
            } else {
                result += chars[Math.floor(Math.random() * chars.length)];
            }
        }
        return result;
    }

    async function fetchPuffPalzIDs() {
      try {
          const details = await fcl.query({
              cadence: getPuffPalzIDs,
              args: (arg, t) => [arg(user.addr, t.Address)],
          });
          setPuffPalzIDs(details);
      } catch (error) {
          console.error("Error fetching Puff Palz details:", error);
      }
  }

  useEffect(() => {
    if (user.addr) {
        fetchPuffPalzIDs();
    }
}, [user.addr]);

  // Function to handle the button click
  const handleShopClick = () => {
    navigate('/shop'); // This will navigate to the /shop route
  };
  
  const handleCollectionClick = () => {
    navigate('/collection'); // This will navigate to the /shop route
  };

  const handleMenagerieClick = () => {
    navigate('/menagerie'); // This will navigate to the /shop route
  };




  return (
    <div className="app">
        <div className="top-container">
            <h1>PUFF PALZ</h1>
            <h3>NEVER PUFF ALONE</h3>
        </div>
        <div className="gradient-divider"></div> 
        <div className='top-container'>
          <h2>Initialize Account</h2>
          <h4>Initialize your Flow account to receive Puff Palz in your wallet.</h4>
          {isInitializing ? (
          <p>Initializing...</p>
        ) : (
          <button 
            onClick={handleInitPuffPalz}
            //disabled={listings.price === 0.0}
            //onClick={handleMint}
            //disabled={!hasPreSale && listings.price === 0.0}
            className="button-new">
          INITIALIZE
        </button>
        )}
        </div>
   {/*}
        <div className="time-container" id="countdown">
          <div className="time-section">
            <span>{countdownTimer.days}</span>
            <div className="time-label">days</div>
          </div>
          <div className="time-section">
            <span>{countdownTimer.hours}</span>
            <div className="time-label">hours</div>
          </div>
          <div className="time-section">
            <span>{countdownTimer.minutes}</span>
            <div className="time-label">minutes</div>
          </div>
          <div className="time-section">
            <span>{countdownTimer.seconds}</span>
            <div className="time-label">seconds</div>
          </div>
  </div>*/}
        <div className="gradient-divider"></div> 
        <div className='top-container'>
          <h2>Mint Puff Palz</h2>
          <h4>Pick up a Puff Pal today.</h4>
          <button onClick={handleShopClick} className="button-new">
          SHOP
        </button>
        </div>

        <div className="gradient-divider"></div> 
        <div className='top-container'>
          <h2>View your Puff Palz</h2>
          <h4>View your Puff Palz.</h4>
          <button onClick={handleMenagerieClick} className="button-new">
          Menagerie
        </button>
        </div>
        <div className="gradient-divider"></div> 
        <div className='top-container'>
          <h2>View Puff Palz Collection</h2>
          <h4>Pick up a Puff Pal today.</h4>
          <button onClick={handleCollectionClick} className="button-new">
          Collection
        </button>
        </div>
        {/*
        <div className="top-container">
            <h2>PRE-SALE</h2>
            <h3>Party Favorz NFT Holders! <br></br>Pre-Order Your Puff Pal Now!</h3>
            <h4>14.20 $FLOW</h4>
            <p>{420 - preSaleCount}/420</p>
            // Inside your return statement, update the conditional rendering like this:
            {isProcessing ? (
              // Display loading dots when processing
              <p>{dots}</p>
            ) : hasPreSale ? (
              // Display thank you message if the presale has already been done
              <p>Thank you for pre-ordering with Puff Palz!</p>
            ) : (
              // Otherwise, show the pre-order button
              <button 
                onClick={handleButtonClick} 
               // disabled={!ownsPF} // The button is disabled if the user does not own a prerequisite item
                className="button-new"
              >
                Pre-Order
              </button>
            )}

          </div>*/}
        <div className="gradient-divider"></div> 
        <div className='top-container'>
            <h2>STATS</h2>
        </div>
        <div className='three-columns'>
          <div className='tile'>
            <h4>$FLOW BALANCE <br></br>{flowBalance}</h4>
            <button className="button-bgd" 
              onClick={() => window.open('https://www.moonpay.com/buy/flow', '_blank')}>
              ADD FUNDS
            </button>


          </div>
          <div className='tile'>
            <h4>PARTY FAVORZ<br></br>{partyFavorzOwned}</h4>
            <button className="button-bgd" 
              onClick={() => window.open('https://www.flowty.io/collection/0xc4b1f4387748f389/TouchstoneSnowyGlobez', '_blank')}>
              VISIT MARKETPLACE
            </button>

          </div>
          <div className='tile'>
            <h4>PUFF PALZ<br></br>{puffPalzIDs.length}</h4>

            {!hasPreSale ? (
                <button 
                    onClick={handleButtonClick} 
                    //disabled={!ownsPF}
                    className="button-bgd"
                >
                    PRE-ORDER
                </button>
            ) : (
                <p>Thank you!</p>
            )}
          </div>
        </div>

    </div>
  );
}

export default Waiting;
