import React from 'react';
import './footer.css';
import logo from '../../assets/images/logo_trans.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faStore } from '@fortawesome/free-solid-svg-icons';

export default function Footer() {
    return (
        <footer>
            <div className="footer">
                <div className="text-left">
                    <p>Puff Palz All Rights Reserved</p>
                </div>
                <div className="logo-foot">
                    <img src={logo} alt="Puff Palz Logo" />
                </div>
                <div className="social-links">
                    <a href="https://x.com/PuffPalz" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faXTwitter} /> {/* Use the new X icon */}
                    </a>
                    <a href="https://discord.gg/QM8wKFwH" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faDiscord} />
                    </a>
                    <a href="https://t.co/po3ZVLTMHF" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faStore} />
                    </a>
                </div>
            </div>
        </footer>
    );
}
