import React, { useState, useEffect } from 'react';
import '../../App.css';
import logo from '../../assets/images/logoMain.webp';
import * as fcl from '@blocto/fcl';
import { getPuffPalzDetails } from '../../cadence/scripts/getPuffPalzDetails';
import { getPuffPalzIDs } from '../../cadence/scripts/getPuffPalzIDs';
import { transferPuffPalz } from '../../cadence/transactions/transferPuffPalz';

function Zoo() {
    const [user, setUser] = useState({ loggedIn: false, addr: null });
    const [puffPalz, setPuffPalz] = useState([]);
    const [puffPalzIDs, setPuffPalzIDs] = useState([]);
    const [targetAddress, setTargetAddress] = useState('');
    const [selectedNftId, setSelectedNftId] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    
    
    useEffect(() => {
        const unsubscribe = fcl.currentUser().subscribe(user => {
            setUser({ loggedIn: user.loggedIn, addr: user.addr });
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (user.addr) {
            fetchPuffPalzDetails();
            fetchPuffPalzIDs();
        }
    }, [user.addr]);

    async function fetchPuffPalzDetails() {

        try {
            const details = await fcl.query({
                cadence: getPuffPalzDetails,
                args: (arg, t) => [arg(user.addr, t.Address)],
            });
            setPuffPalz(details);
        } catch (error) {
            console.error("Error fetching Puff Palz details:", error);
        }
    }

    async function fetchPuffPalzIDs() {
        try {
            const details = await fcl.query({
                cadence: getPuffPalzIDs,
                args: (arg, t) => [arg(user.addr, t.Address)],
            });
            setPuffPalzIDs(details);
        } catch (error) {
            console.error("Error fetching Puff Palz details:", error);
        }
    }

    const handleTransfer = async(address, id) => {
        setIsProcessing(true);
        try {
            const txid = await fcl.mutate({
                cadence: transferPuffPalz,
                args: (arg, t) => [
                    arg(address, t.Address),
                    arg(Number(id), t.UInt64) // Ensuring id is treated as an array of UInt64
                ],
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999,
            });
            await fcl.tx(txid).onceSealed();
            setIsProcessing(false);
            setTargetAddress(''); // Clear the target address input
            setSelectedNftId(''); // Reset the selected NFT ID
            fetchPuffPalzIDs();
            fetchPuffPalzDetails();
        } catch(error) {
            console.log("Error transferring Puff Palz: ", error);
            setIsProcessing(false);
        }
    };



    const isValidFlowAddress = address => {
        return /^0x[a-fA-F0-9]{16}$/.test(address);
    };

    return (
        <div className='app'>

            <div className='top-container'>
                <h1>Menagerie</h1>
            </div>
            <div className='gradient-divider-3'></div>
            <div className='top-container'>
                <h2>Tools</h2>
            </div>
                
                <div className="puff-port">
                    <h2>Transfer Puff Palz</h2>
                    <input
                        type="text"
                        placeholder="Enter recipient's Flow address"
                        value={targetAddress}
                        onChange={e => setTargetAddress(e.target.value)}
                        className={isValidFlowAddress(targetAddress) ? 'valid' : 'invalid'}
                    />
                    <select onChange={e => setSelectedNftId(e.target.value)} value={selectedNftId}>
                        <option value="">Select Puff Pal ID</option>
                        {puffPalzIDs.map(id => (
                            <option key={id} value={id}>ID: {id}</option>
                        ))}
                    </select>
                    {isProcessing ? (
                        <p>Transferring...</p>
                    ) : (
                        <button onClick={() => handleTransfer(targetAddress, selectedNftId)}
                                disabled={!isValidFlowAddress(targetAddress) || !selectedNftId}>
                            Transfer
                        </button>
                    )}
                </div>
                <div className='gradient-divider-3'></div>
                <div className='top-container'>
            <h2>MY puff palz</h2>
            </div>
            <div className="nft-gallery">
                {puffPalz.map((pal, index) => (
                    <div key={index} className="nft-tile">
                        <img src={`./${pal.name}.jpeg`} alt={pal.name} className="nft-image"/>
                        <h3>{pal.name}</h3>
                        <div className="trait-grid">
                            {Object.entries(pal.traits).map(([trait, value], idx) => (
                                <div key={idx} className="trait-column">
                                    <div className="trait-name">{trait}</div>
                                    <div className="trait-value">{value}</div>
                                </div>
                            ))}
                        </div>
                        <div className="nft-id-tab">{pal.id}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Zoo;
