import React from 'react';
import '../../App.css';

// Dynamically import images
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { 
    images[item.replace('./', '').replace(' Medium.jpeg', '')] = r(item); 
  });
  return images;
}

const smokersImages = importAll(require.context('../../assets/images/smokers/', false, /\.(jpeg)$/));
const pushersImages = importAll(require.context('../../assets/images/pushers/', false, /\.(jpeg)$/));
const growersImages = importAll(require.context('../../assets/images/growers/', false, /\.(jpeg)$/));
const specialImages = importAll(require.context('../../assets/images/special/', false, /\.(jpeg)$/));

function Collection() {
  // Helper function to render images in a grid
  const renderImageTiles = (images, className = "image-grid") => (
    <div className={className}>
      {Object.keys(images).map(key => (
        <div className="image-tile" key={key}>
          <img src={images[key]} alt={key} />
          <p>{key.replace('.png', '').replace(/_/g, ' ')}</p>
        </div>
      ))}
    </div>
  );
  

  return (
    <div className="App">
      <div className='top-container'>
        <h1>Puff Palz Collection</h1>

      </div>
      <div className="gradient-divider-3"></div> 
      <div className='image-section'>

        <h2>Smokers</h2>
        {renderImageTiles(smokersImages)}
        <div className="gradient-divider-2"></div> 
        <h2>Growers</h2>
        {renderImageTiles(growersImages)}
        <div className="gradient-divider-2"></div> 
        <h2>Pushers</h2>
        {renderImageTiles(pushersImages)}
        <div className="gradient-divider-2"></div> 
        <h2>Top of the Food Chain</h2>
        {renderImageTiles(specialImages, "top-food-chain-grid")}

      </div>
    </div>
  );
}

export default Collection;
