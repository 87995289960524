export const getPuffPalzDetails = `
import PuffPalz from 0xa3eb9784ae7dc9c8

access(all) struct PuffPalzNFTData {
    access(all) let id: UInt64
    access(all) let name: String
    access(all) let description: String
    access(all) let image: String
    access(all) let traits: {String: String}

    init(id: UInt64, name: String, description: String, image: String, traits: {String: String}) {
        self.id = id
        self.name = name
        self.description = description
        self.image = image
        self.traits = traits
    }
}

access(all) fun main(accountAddress: Address): [PuffPalzNFTData] {
    let account = getAccount(accountAddress)

    // Borrow a reference to the user's PuffPalz collection
    let collectionRef = account
        .capabilities.borrow<&{PuffPalz.CollectionPublic}>(PuffPalz.CollectionPublicPath)
        ?? panic("Could not borrow a reference to the PuffPalz collection")

    // Get all the NFT IDs in the user's collection
    let nftIDs = collectionRef.getIDs()

    var nftData: [PuffPalzNFTData] = []

    // Loop through the NFT IDs and get the metadata
    for id in nftIDs {
        let nft = collectionRef.borrowPuffPalz(id: id)
            ?? panic("Could not borrow reference to the NFT with ID ".concat(id.toString()))


        // Create a dictionary to store the traits
        var traitsCopy: {String: String} = {}

                // Copy each trait from the reference to the new dictionary
        for key in nft.traits.keys {
            traitsCopy[key] = nft.traits[key]!
        }

        // Extract the metadata
        let metadata = PuffPalzNFTData(
            id: nft.id,
            name: nft.name,
            description: nft.description,
            image: nft.image,
            traits: traitsCopy // Use the dereferenced traits copy
        )

        // Append the NFT data to the array
        nftData.append(metadata)
    }

    // Return the array of NFT metadata
    return nftData
}

`