export const transferFlowLogPurchase = `
import FungibleToken from 0xf233dcee88fe0abe
import FlowToken from 0x1654653399040a61
import PreSaleMetadata from 0x66b60643244a7738

transaction(amount: UFix64, timeStamp: String, txID: String, signerAddress: Address) {
    let senderVaultRef: &FlowToken.Vault
    let primaryRecipientVault: Capability<&FlowToken.Vault{FungibleToken.Receiver}>
    let secondaryRecipientVault: Capability<&FlowToken.Vault{FungibleToken.Receiver}>


    prepare(signer: AuthAccount) {
        // Borrow a reference to the signer's vault
        self.senderVaultRef = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault) 
            ?? panic("Unable to borrow reference to sender vault")
        
        // Get capabilities for both recipient's receiver Vaults
        self.primaryRecipientVault = getAccount(0xc4b1f4387748f389).getCapability<&FlowToken.Vault{FungibleToken.Receiver}>(/public/flowTokenReceiver)
        self.secondaryRecipientVault = getAccount(0x66b60643244a7738).getCapability<&FlowToken.Vault{FungibleToken.Receiver}>(/public/flowTokenReceiver)
    }

    execute {
        // Calculate the split amounts
        let primaryAmount = amount * 0.99
        let secondaryAmount = amount * 0.01

        // Withdraw the amounts for each recipient from the sender's vault
        let primaryVault <- self.senderVaultRef.withdraw(amount: primaryAmount)
        let secondaryVault <- self.senderVaultRef.withdraw(amount: secondaryAmount)

        // Deposit to the primary recipient's vault
        let primaryReceiver = self.primaryRecipientVault.borrow() 
            ?? panic("Could not borrow receiver reference from primaryRecipientVault capability")
        primaryReceiver.deposit(from: <-primaryVault)



        // Deposit to the secondary recipient's vault
        let secondaryReceiver = self.secondaryRecipientVault.borrow() 
            ?? panic("Could not borrow receiver reference from secondaryRecipientVault capability")
        secondaryReceiver.deposit(from: <-secondaryVault)

        // Log the secondary transaction metadata
        PreSaleMetadata.addMetadata(
            userAddress: signerAddress, 
            amount: amount, 
            timeStamp: timeStamp, 
            txID: txID
        )
    }
}

`