export const getPuffPalzIDs = `
import PuffPalz from 0xa3eb9784ae7dc9c8
import NonFungibleToken from 0x1d7e57aa55817448

pub fun main(address: Address): [UInt64] {
    let account = getAccount(address)
    let collectionRef = account.getCapability<&PuffPalz.Collection{NonFungibleToken.CollectionPublic}>(PuffPalz.CollectionPublicPath).borrow()
        ?? panic("Could not borrow reference to the collection")

    return collectionRef.getIDs()
}

`