export const purchasePuffPal = `
// Mainnet
import NonFungibleToken from 0x1d7e57aa55817448
import PuffPalz from 0xa3eb9784ae7dc9c8
import FungibleToken from 0xf233dcee88fe0abe
import FlowToken from 0x1654653399040a61
import PuffPalzMainStorefront from 0xa3eb9784ae7dc9c8


// Testnet
// import NonFungibleToken from 0x631e88ae7f1d7c20
// import PuffPalz from 0xac391223d88c98e4
// import FungibleToken from 0x9a0766d93b6608b7
// import FlowToken from 0x7e60df042a9c0868
// import PuffPalzMainStorefront from 0xac391223d88c98e4

transaction(listingResourceID: UInt64, storefrontAddress: Address) {
    let paymentVault: @FungibleToken.Vault
    let nftCollection: &NonFungibleToken.Collection{NonFungibleToken.Receiver}
    let storefront: &PuffPalzMainStorefront.Storefront{PuffPalzMainStorefront.StorefrontPublic}
    let listing: &PuffPalzMainStorefront.Listing{PuffPalzMainStorefront.ListingPublic}
    let salePrice: UFix64
    let balanceBeforeTransfer: UFix64
    let flowTokenVault: &FlowToken.Vault

    prepare(buyer: AuthAccount) {
                // Initialize the buyer's collection if they do not already have one
        if buyer.borrow<&PuffPalz.Collection>(from: PuffPalz.CollectionStoragePath) == nil {
            let collection <- PuffPalz.createEmptyCollection() as! @PuffPalz.Collection
            buyer.save(<-collection, to: PuffPalz.CollectionStoragePath)
            
            buyer.link<&PuffPalz.Collection{NonFungibleToken.CollectionPublic, PuffPalz.CollectionPublic}>(
                PuffPalz.CollectionPublicPath,
                target: PuffPalz.CollectionStoragePath
            )
             ?? panic("Could not link collection Pub Path");
        }

                // Get the storefront reference from the seller
        self.storefront = getAccount(storefrontAddress)
            .getCapability<&PuffPalzMainStorefront.Storefront{PuffPalzMainStorefront.StorefrontPublic}>(
                PuffPalzMainStorefront.StorefrontMainPublicPath
            )!
            .borrow()
            ?? panic("Could not borrow Storefront from provided address")

                    // Get the listing by ID from the storefront
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No Offer with that ID in Storefront")
        self.salePrice = self.listing.getDetails().salePrice

                // Get a DUC vault from Dapper's account
        self.flowTokenVault = buyer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
            ?? panic("Cannot borrow FlowToken vault from account storage")
        self.balanceBeforeTransfer = self.flowTokenVault.balance
        self.paymentVault <- self.flowTokenVault.withdraw(amount: self.salePrice)

                // Get the collection from the buyer so the NFT can be deposited into it
        self.nftCollection = buyer.borrow<&NonFungibleToken.Collection{NonFungibleToken.Receiver}>(
            from: PuffPalz.CollectionStoragePath
        ) ?? panic("Cannot borrow NFT collection receiver from account")



    }

    execute {
        self.listing.purchase(
            payment: <- self.paymentVault,
            collection: self.nftCollection
        )

    }
}

`