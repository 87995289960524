export const initPuffPalz = `
import NonFungibleToken from 0x1d7e57aa55817448
import PuffPalz from 0xa3eb9784ae7dc9c8

transaction {
    prepare(signer: AuthAccount) {
        // Check if the account already contains a PuffPalz Collection
        if signer.borrow<&PuffPalz.Collection>(from: PuffPalz.CollectionStoragePath) == nil {
            // Create a new PuffPalz Collection and save it to the account's storage
            let collection <- PuffPalz.createEmptyCollection()
            signer.save(<-collection, to: PuffPalz.CollectionStoragePath)
            
            // Create a public capability for the collection
            signer.link<&PuffPalz.Collection{NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, PuffPalz.CollectionPublic}>(
                PuffPalz.CollectionPublicPath,
                target: PuffPalz.CollectionStoragePath
            )
        }
    }

}

`