import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import * as fcl from '@onflow/fcl';

import './App.css';
import Shop from './pages/shop/shop';
import Waiting from './pages/waiting/waiting';
import Header from './components/header/header';
import Collection from './pages/collection/collection';
import Footer from './components/footer/footer';
import Zoo from './pages/zoo/zoo';
// import Merchandise from './pages/merch/merch';
// import Contact from './Contact';

function App() {
  const [user, setUser] = useState({ loggedIn: false, addr: null });
  const [underConstruction, setUnderConstruction] = useState(false); // State to control under construction message

  useEffect(() => {
    const unsubscribe = fcl.currentUser().subscribe(user => {
      setUser({
        loggedIn: user.loggedIn,
        addr: user.addr,
      });
    });

    return () => unsubscribe();
  }, []);

  // Specify the allowed Blocto addresses
  const allowedAddresses = ['0xc4b1f4387748f389'];
  const isAllowedAddress = allowedAddresses.includes(user.addr);

  if (underConstruction) {
    // Display under construction message
    return (
      <div className="app-background">
        <div className='top-container'>
          <h1 className='const'>Site Under Construction</h1>
          <p className='const'>We'll be back soon!</p>
        </div>
      </div>
    );
  }

  // Regular app content
  return (
    <div className="app-background">
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Waiting />} />
          <Route path='/menagerie' element={<Zoo /> } />
          <Route path='/shop' element={<Shop /> } />
          <Route path='/collection' element={ <Collection /> } />
          {/*<Route path='/merch' element={ <Merchandise /> } /> */}
          {/* Additional authenticated routes here */}
          {/* <Route path='/contact' element={user.loggedIn && isAllowedAddress ? <Contact /> : <Navigate replace to="/" />} /> */}
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
